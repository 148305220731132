/* Navbar.css */
.navbar {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 105px;
}

.nav-menu {
    display: flex;
    gap: 1rem;
}

.nav-menu.expanded {
    display: block;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-menu ul li a {
    text-decoration: none;
    color: #333;
}

.hamburger {
    display: none;
    background: none;
    border: none;
}

@media (max-width: 768px) {
    .nav-menu {
        display: none;
    }
    .nav-menu.expanded {
        display: block;
    }
    .hamburger {
        display: block;
    }
}
