.marker {
  background-image: url('custom_marker.png');
  color: white;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
}
.map-container {
  height: 60vh;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

